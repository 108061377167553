<template>
  <div>
    <loading-overlay :active="loading" :is-full-page="true" loader="bars"/>
    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
      @update:show="closeModal()"
    >
      <CRow class="justify-content-center">
        <CCol sm="12" lg="11">
          <CInput
            :label="`${$t('label.name')} (ES)`"
            :placeholder="`${$t('label.classificationName')} (ES)`"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
            v-model="$v.EventGpoNameEs.$model"
            addLabelClasses="required text-right"
            :is-valid="hasError($v.EventGpoNameEs)"
            :invalid-feedback="errorMessage($v.EventGpoNameEs)"
            v-uppercase
          />
          <CInput
            :label="`${$t('label.name')} (EN)`"
            :placeholder="`${$t('label.classificationName')} (EN)`"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
            v-model="$v.EventGpoNameEn.$model"
            addLabelClasses="required text-right"
            :is-valid="hasError($v.EventGpoNameEn)"
            :invalid-feedback="errorMessage($v.EventGpoNameEn)"
            v-uppercase
          />
          <CSelect
            v-if="isEdit"
            :options="statusOptions"
            :value.sync="Status"
            :label="$t('label.status')"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
            addLabelClasses="required text-right"
            :is-valid="statusSelectColor"
          />
        </CCol>
      </CRow>

      <template #footer>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          @click.stop="isEdit ? statusConfirmation(EventGpoItem.FgEventGpoAct, Status, Submit) : Submit()"
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="closeModal()"
        >
          <CIcon name="x" /><span class="ml-1"
            >{{$t('button.cancel')}}</span
          >
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import ClassificationValidations from '@/_validations/event/classificationValidations';
import UpperCase from "@/_validations/uppercase-directive";

function data() {
  return {
    isSubmit: false,
    modalActive: false,
    loading: false,
    EventGpoId: '',
    EventGpoNameEs: '',
    EventGpoNameEn: '',
    Status: 0,
  };
}

function Submit() {
  try {
    this.isSubmit = true;
		this.loading = true;
    this.$v.$touch();
    if (this.$v.$error) {
      throw this.$t('label.errorsPleaseCheck');
    }

    let EventGpoJson = this.isEdit ? {
      EventGpoId: this.EventGpoId,
      EventGpoNameEs: this.EventGpoNameEs,
      EventGpoNameEn: this.EventGpoNameEn,
      Status: this.Status,
    }:{
      EventGpoNameEs: this.EventGpoNameEs,
      EventGpoNameEn: this.EventGpoNameEn,
    };
    let metodo = this.isEdit ? 'PUT':'POST';
    let ruta = this.isEdit ? 'EventGpo-update': 'EventGpo-insert';
    this.$http
      .ejecutar( metodo, ruta, EventGpoJson, { root: 'EventGpoJson' })
      .then((response) => {
				this.closeModal();
				this.$emit('List-Update');
        this.notifySuccess({text: response.data.data[0].Response})
        this.loading = false;
        this.isSubmit = false;
      }).catch((e) => {
        this.isSubmit = false;
        this.loading = false;
        this.notifyError({text: e});
      });
  } catch (e) {
    this.isSubmit = false;
    this.loading = false;
    this.notifyError({text: e});
  }
}

function titleModal() {
  if (this.isEdit) {
    let _lang = this.$i18n.locale;
    let title = Object.keys(this.EventGpoItem).length == 0 ? '' :
      _lang=='en' ? this.EventGpoItem.EventGpoNameEn : this.EventGpoItem.EventGpoNameEs;
    return `${this.$t('label.edit')} ${this.$t('label.classification')}: ${title}`;
  } else {
    return `${this.$t('label.nueva')} ${this.$t('label.classification')}`;
  }
}

function statusSelectColor() {
  return this.Status === 1;
}

function getdata(val) {
  this.EventGpoId = val.EventGpoId;
  this.EventGpoNameEs = val.EventGpoNameEs;
  this.EventGpoNameEn = val.EventGpoNameEn;
  this.Status = val.FgEventGpoAct ? 1 : 0;
  this.$v.$touch();
}

function closeModal() {
  this.EventGpoId = '';
  this.EventGpoNameEs = '';
  this.EventGpoNameEn = '';
  this.Status = 0;
  this.$v.$reset();
  this.$emit('Close-Modal');
}

export default {
  name: 'modal-classification',
  props: { modal: Boolean, isEdit: Boolean, EventGpoItem: Object },
  data,
  mixins: [
    ModalMixin,
  ],
  validations: ClassificationValidations,
  directives: UpperCase,
  methods: {
    statusSelectColor,
    closeModal,
    getdata,
    Submit,
  },
  computed: {
    titleModal,
  },
  watch: {
    modal: function(val){
      this.modalActive = val;
      if (val && this.isEdit==true) {
        this.getdata(this.EventGpoItem);
      }
    }
  },
 
};
</script>